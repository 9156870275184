/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import {colors} from '../constants'

const buttonStyles = css`
    text-decoration: none;
    button {
        font-size: 1rem;
        border-radius: 2rem;
        display: inline-block;
        font-family: inherit;
        font-weight: 400;
        letter-spacing: 0.02rem;
        line-height: 1.2;
        transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
        margin-right: 1rem;
        margin-bottom: 1rem;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
        background-color: ${colors.brandBlue};
        border: none;
        color: ${colors.whisperLight};
        padding: 0.8rem 2rem;
        &:hover {
            cursor: pointer;
            background-color: ${colors.brandBlueLight2};
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }
`

const disabled = css`
    pointer-events: none;
    button {
        background-color: #f4f5fb;
        color: ${colors.midnightLight};
    }
`

const Button = ({text, link, onClick, isDisabled, ariaLabel, overrideStyles}) => {
    return (
        <a
            css={
                isDisabled
                    ? css`
                          ${buttonStyles};
                          ${disabled};
                          ${overrideStyles};
                      `
                    : css`
                          ${buttonStyles};
                          ${overrideStyles};
                      `
            }
            href={link}
            aria-label={ariaLabel}
        >
            <button className="conversion-button" disabled={isDisabled} onClick={onClick}>
                {text}
            </button>
        </a>
    )
}

Button.propTypes = {
    isDisabled: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    ariaLabel: PropTypes.string,
    overrideStyles: PropTypes.string,
}

Button.defaultProps = {
    isDisabled: false,
    link: `https://www.startpage.com`,
    // eslint-disable-next-line no-console
    onClick: () => console.log('button clicked'),
    text: `Yes, install`,
    ariaLabel: `Yes, install`,
    overrideStyles: ``,
}

export default Button
