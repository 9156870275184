/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import StartpageLogoNew from '../../images/startpage-logo-new.svg'
import {colors} from '../../constants'

const Header = ({homepageUrl, ariaLabel}) => {
    return (
        <header
            css={css`
                padding: 3rem 5rem 1.45rem 0;
                background-color: ${colors.whisperLight};
                @media (max-width: 950px) {
                    padding: 3rem 0 1.45rem;
                }
            `}
        >
            <div css={css``}>
                <a href={homepageUrl} aria-label={ariaLabel}>
                    <StartpageLogoNew
                        css={css`
                            height: 45px;
                            width: 179px;
                            @media (max-width: 950px) {
                                display: block;
                                margin: auto;
                            }
                        `}
                    />
                </a>
            </div>
            <h1
                css={css`
                    font-size: 1.25rem;
                    font-weight: normal;
                    margin-top: 0.75rem;
                    @media (max-width: 950px) {
                        text-align: center;
                    }
                `}
            >
                The World's Most Private Search Engine
            </h1>
        </header>
    )
}

Header.propTypes = {
    homepageUrl: PropTypes.string,
    ariaLabel: PropTypes.string,
}

Header.defaultProps = {
    homepageUrl: `https://www.startpage.com`,
    ariaLabel: `go to startpage.com homepage`,
}

export default Header
