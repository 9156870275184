/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import {css, Global} from '@emotion/react'

import {logDplWithExtensionParams, EVENTS} from '../../util/dpl'
import {chromeProtectionExtensionLink, firefoxProtectionExtensionLink} from '../../util/extensionStoreLinks'

import SEO from '../seo'
import Header from './header'
import Footer from '../templates/protection-v1/Footer'
import WidgetSearchInPrivate from '../widget-search-in-private'
import {colors, globalStyles} from '../../constants'

const IndexUnsupported = ({experiment}) => {
    const site = useStaticQuery(graphql`
        query IndexUnsupportedImgQuery {
            firefoxLogo: file(relativePath: {eq: "logoFirefox.png"}) {
                childImageSharp {
                    fixed(height: 135, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            chromeLogo: file(relativePath: {eq: "logoChrome.png"}) {
                childImageSharp {
                    fixed(height: 135, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            site {
                siteMetadata {
                    ...ProtectionLanding
                }
            }
        }
    `)

    const {landingEulaLink, footerBlogAsset, footerLinks} = site.site.siteMetadata.privacyprotect.en

    const [hasFiredVisit, setHasFiredVisit] = useState(false)

    const logAndRedirect = (e, protobufEvent, type, redirectUrl) => {
        e.preventDefault()
        return logDplWithExtensionParams(protobufEvent, {experiment, type})
            .then(() => {
                window.location.href = redirectUrl
            })
            .catch((err) => {
                if (err) console.error(err)
                window.location.href = redirectUrl
            })
    }

    useEffect(() => {
        if (!hasFiredVisit) {
            setHasFiredVisit(true)
            logDplWithExtensionParams(EVENTS.StartpagePageLoad, {experiment})
        }
    }, [hasFiredVisit, experiment])

    return (
        <>
            <SEO title="Startpage" description="The World's Most Private Search Engine" />

            <section
                className="main"
                css={css`
                    max-width: 100%;
                    overflow: hidden;
                    max-width: 1000px;
                    margin: 0 auto;
                    padding: 0 1rem;
                    @media (min-width: 1100px) {
                        min-height: calc(100vh - 180px);
                    }
                `}
            >
                <Global
                    styles={css`
                        html,
                        body {
                            margin: 0;
                            font-family: 'Inter', sans-serif;
                        }
                        ${globalStyles};
                    `}
                />
                <Header />
                <div
                    className="info"
                    css={css`
                        background: ${colors.brandBlueUltraLight};
                        margin: 0 auto 4rem;
                        padding: 4rem 3rem 3rem;
                        text-align: center;
                    `}
                >
                    <h3
                        css={css`
                            font-weight: 600;
                            font-size: 1.35rem;
                        `}
                    >
                        Unable to Install Extension, Browser Not Supported
                    </h3>
                    <p
                        css={css`
                            max-width: 450px;
                            margin: 1rem auto;
                            line-height: 1.4;
                        `}
                    >
                        To continue installing our private search engine extension, please select a supported browser
                    </p>
                    <div
                        className="browser-links"
                        css={css`
                            display: flex;
                            justify-content: center;

                            @media (max-width: 600px) {
                                flex-direction: column;
                            }

                            a {
                                margin: 1rem 2rem 0;
                                color: ${colors.brandBlue};
                                &:hover {
                                    text-decoration: underline;
                                }
                                p {
                                    color: ${colors.brandBlue};
                                }
                            }
                            p {
                                margin-top: 0.75rem;
                            }
                        `}
                    >
                        <a
                            href={chromeProtectionExtensionLink()}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                                logAndRedirect(
                                    e,
                                    EVENTS.StartpageClick,
                                    'unsupported-chrome-cta',
                                    chromeProtectionExtensionLink(),
                                )
                            }}
                        >
                            <Img fixed={site.chromeLogo.childImageSharp.fixed} />
                            <p>Google Chrome</p>
                        </a>
                        <a
                            href={firefoxProtectionExtensionLink()}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                                logAndRedirect(
                                    e,
                                    EVENTS.StartpageClick,
                                    'unsupported-firefox-cta',
                                    firefoxProtectionExtensionLink(),
                                )
                            }}
                        >
                            <Img fixed={site.firefoxLogo.childImageSharp.fixed} />
                            <p>Firefox</p>
                        </a>
                    </div>
                </div>

                <WidgetSearchInPrivate
                    onClick={(e) => {
                        logAndRedirect(
                            e,
                            EVENTS.StartpageClick,
                            'unsupported-homepage-cta',
                            'https://www.startpage.com',
                        )
                    }}
                />
            </section>

            <Footer
                languageCode="en"
                links={footerLinks}
                footerBlogAsset={footerBlogAsset}
                additionalLinks={landingEulaLink}
                responsive
            />
        </>
    )
}

IndexUnsupported.propTypes = {
    experiment: PropTypes.string,
}

IndexUnsupported.defaultProps = {
    experiment: `unsupported`,
}

export default IndexUnsupported
