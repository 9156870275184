/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import {colors} from '../constants'

import Button from './button.js'

import PrivacyProtectComputer from '../images/privacy-protect-computer.svg'

const WidgetSearchInPrivate = ({onClick}) => {
    return (
        <div
            className="widget-search-in-private"
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 4rem;
                @media (max-width: 950px) {
                    flex-direction: column;
                }
            `}
        >
            <PrivacyProtectComputer />
            <div
                css={css`
                    max-width: 450px;
                    margin: 0 0 0 35px;
                    @media (max-width: 950px) {
                        text-align: center;
                        margin: 2rem 0 0;
                    }
                `}
            >
                <h3
                    css={css`
                        font-weight: 600;
                        color: ${colors.midnight};
                        font-size: 24px;
                        margin-bottom: 24px;
                    `}
                >
                    Search in private now.
                </h3>
                <p
                    css={css`
                        font-size: 16px;
                        margin: 1rem 0;
                        line-height: 24px;
                        margin-bottom: 24px;
                    `}
                >
                    Startpage is the search engine that won't track, save search history, retarget with ads or store
                    your personal information.
                </p>
                <Button
                    onClick={onClick}
                    text="Continue to Startpage"
                    link="https://www.startpage.com"
                    overrideStyles="
                        button {
                            box-shadow: none;
                            padding: 14px 25px;
                            border-radius: 4px;
                        }
                    "
                />
            </div>
        </div>
    )
}

WidgetSearchInPrivate.propTypes = {
    onClick: PropTypes.func,
}

WidgetSearchInPrivate.defaultProps = {
    onClick: () => console.log('button clicked'),
}

export default WidgetSearchInPrivate
